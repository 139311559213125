import { render } from "./orderDetail.vue?vue&type=template&id=6ad3b3f8&scoped=true"
import script from "./orderDetail.vue?vue&type=script&lang=js"
export * from "./orderDetail.vue?vue&type=script&lang=js"

import "./orderDetail.vue?vue&type=style&index=0&id=6ad3b3f8&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-6ad3b3f8"
/* hot reload */
if (module.hot) {
  script.__hmrId = "6ad3b3f8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6ad3b3f8', script)) {
    api.reload('6ad3b3f8', script)
  }
  
  module.hot.accept("./orderDetail.vue?vue&type=template&id=6ad3b3f8&scoped=true", () => {
    api.rerender('6ad3b3f8', render)
  })

}

script.__file = "src/views/order/orderDetail/orderDetail.vue"

export default script