
<template>
    <div class="orderdetail">
        <headBack>
            <template v-slot:title>
                <div class='title'>
                    订单详情
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>
        <div class='top'>
            <img src='https://static.yihu365.cn/img/h5Img/assets/img/home/insurance_top.png' @click='router.push({name:"insurance"})'/>
        </div>
        <!-- <div class='line'></div> -->
      <div class="load_box" v-if="orderdetail.orderStatus == '0' && orderdetail.payStatus == '0'">
          <div class="cell">
            <div class="card">
              <span class="timer-loader">Loading&#8230;</span>
            </div>
          </div>
          <div class="load_text">
            正在通知护士抢约，如果20分钟以后无护士抢约，将为您进行人工调度
          </div>
      </div>

        <div class='information'>
            <div class='title'>
                <div class='name'>预约服务信息</div>
                <div class='status' :class='{grey:(orderdetail.orderStatus == -1 || orderdetail.orderStatus == 7 || orderdetail.orderStatus == 3 || orderdetail.orderStatus == 6 )}'>{{changeText(orderdetail.orderStatus,orderdetail.payStatus)}}</div>
            </div>
            <div class='content'>
                <div class='list'>
                    <div class='list_title'>服务信息</div>
                    <div>{{orderdetail.service}}/{{orderdetail.times}}次</div>
                </div>
              <div class='list'>
                <div class='list_title'>服务套餐</div>
                <div>{{orderdetail.packageInformation}}</div>
              </div>
                <!-- <div class='list'>
                    <div class='list_title'>服务记录</div>
                    <div>已服务3次</div>
                    <van-icon name="arrow" class='arrow' @click='router.push({
                        name : "serviceRecord"
                    })'/>
                </div> -->
                <div class='list'>
                    <div class='list_title'>预约号</div>
                    <div>{{orderdetail.orderId}}</div>
                </div>
                <div class='list'>
                    <div class='list_title'>下单时间</div>
                    <div>{{orderdetail.CREATE_TIME}}</div>
                </div>
                <!-- <div class='list'>
                    <div class='list_title'>疾病</div>
                    <div>无</div>
                </div> -->
                <div class='list'>
                    <div class='list_title'>服务费</div>
                    <div>{{orderdetail.price}}元</div>
                </div>

                <div class='list' v-if='orderdetail.isHasTool'>
                    <div class='list_title'>耗材</div>
                    <div>有{{orderdetail.service}}工具</div>
                </div>
                <div class='list' v-if='orderdetail.pkgPrice'>
                    <div class='list_title'>耗材费</div>
                    <div>{{orderdetail.pkgPrice}}</div>
                </div>
                <div class='list' v-if='orderdetail.voucher_sum'>
                    <div class='list_title'>优惠券</div>
                    <div>{{orderdetail.voucher_sum}}元</div>
                </div>
                <div class='list' v-if='orderdetail.payPrice'>
                    <div class='list_title'>实付金额</div>
                    <div>{{orderdetail.payPrice}}元</div>
                </div>
                <div class='list'>
                    <div class='list_title'>备注</div>
                    <div>{{orderdetail.desc}}</div>
                </div>

            </div>
        </div>
        <div class='line' v-if='nerseShow'></div>

        <div class='information' v-if='nerseShow'>
            <div class='title'>
                <div class='name'>接待护士信息</div>

            </div>
            <div class='content'>
                <div class='list'>
                    <div class='list_title'>护士姓名</div>
                    <div>{{orderdetail.serverUserName}}</div>
                </div>
                <div class='list' @click.stop='conext()'>
                    <div class='list_title'>联系电话</div>
                    <div class='colorblur'>{{orderdetail.serverUserMobile}}</div>
                </div>


            </div>

            <!-- <div class='bottom_nurse'>
                <div class='des' @click='router.push({
                    name : "nurseDetail"
                })'>
                    <img src='https://static.yihu365.cn/img/h5Img/assets/img/order/idcard.png'/>
                    查看护士基本信息
                </div>
                <div class='divider'></div>
                <div class='des'>
                    <img src='https://static.yihu365.cn/img/h5Img/assets/img/order/tel.png'/>
                    联系护士
                </div>
            </div> -->
        </div>
        <div class='line'></div>
        <div class='information'>
            <div class='title'>
                <div class='name'>患者信息</div>

            </div>
            <div class='content'>
                <div class='list'>
                    <div class='list_title'>患者信息</div>
                    <div>
                        <div>{{orderdetail.patientName}}</div>
                        <div style='margin-top:7px'>{{orderdetail.patientSex === '0'?'男':'女'}} {{orderdetail.patientAge}}岁 {{orderdetail.relationship}}</div>
                    </div>
                </div>
                <div class='list'>
                    <div class='list_title'>上门地址</div>
                    <div>
                        <div>{{orderdetail.address}}</div>

                    </div>
                </div>
            </div>
        </div>
        <div class='line'></div>
        <div style='height:60px;background:#f5f5f5'></div>
        <div class='btn_btm_cont'>
            <div class='btn_inner'>
                <!-- <div class='order_btn' @click='payShow = true'>确认已服务</div> -->
                <div class='order_btn' @click='router.push({name:"process"})'>查看流程</div>
                <div class='order_btn' @click.stop='concel()'>取消订单</div>
                <div class='order_btn active_btn' v-if='orderdetail.orderStatus == 1 && orderdetail.serviceTimeStart1 && orderdetail.serviceTimeStart1.length > 0 ' @click.stop='confirmService()'>确认已服务</div>
                <div class='order_btn active_btn' @click.stop='toPay()' v-if='orderdetail.orderStatus == 0 && orderdetail.payStatus == -1'>去支付</div>
            </div>
        </div>

        <van-dialog v-model:show="payShow" show-cancel-button confirmButtonColor='#00C291' confirmButtonText='立即支付'>
            <div class='reson'>支付加急费夜间服务费及其他费用，请与医护人员核对再付款【非充值入口】</div>
            <div class='pay_input'>
                ¥ <van-field v-model="value" placeholder="请输入用户名" type='number'/>
            </div>
        </van-dialog>

        <van-popup
            v-model:show="concelShow"
            closeable
            round
            position="bottom"

        >
            <div class='concel_title'>确定取消预约吗？</div>
            <div class='radio_content'>
                <van-radio-group v-model="checked" checked-color='#00C291'>
                    <van-cell-group>
                        <van-cell title="我临时有事，需取消订单" clickable @click="checked = '1'">
                            <template #right-icon>
                                <van-radio name="1" />
                            </template>
                        </van-cell>
                        <van-cell title="药品/耗材有问题，无法完成服务" clickable @click="checked = '2'">
                            <template #right-icon>
                                <van-radio name="2" />
                            </template>
                        </van-cell>
                    </van-cell-group>
                </van-radio-group>
            </div>
            <div class='concen_tips'>
                订单已有护士接单，因个人原因取消，将扣除30%违约金。 申述请拨打客服电话 400-123-789
            </div>
            <div class='btn_content'>
                <van-button type="default"  round>取消</van-button>
                <van-button type="primary" color='#00C291' round>提交</van-button>
            </div>

        </van-popup>
        <van-dialog
            v-model:show="showDia"
            showCancelButton = true
            confirmButtonColor = "#1083FF"
            confirmButtonText = "提交"
            title = "确认取消预约吗"
            @confirm = "concelOnSubmit"
        >
            <van-radio-group
                v-model="checkedDia"
                icon-size="18"
                @change = "changeRadValue"
            >
              <van-radio name="001">我临时有事，需取消订单</van-radio>
              <van-radio name="002">药品/耗材有问题，无法完成服务</van-radio>
              <van-radio name="004">个人原因不需要了</van-radio>
              <van-radio name="005">其他原因</van-radio>
            </van-radio-group>
          <van-field
              v-model="messageDia"
              name="006"
              rows="3"
              autosize
              type="textarea"
              placeholder="输入原因"
              show-word-limit
              v-if="checkedDia == '005'"
          />
            <div class="txt">订单已有护士接单，因个人原因取消，将扣除30%违约金。申述请拨打客服电话400-800-6996</div>
        </van-dialog>
    </div>
</template>
<script>
import { onMounted, ref, computed } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex';
import { Dialog,Toast } from "vant";
import orderApi from '@axios/order'



export default {
  name: 'orderdetail',
  components: {

  },
  setup () {
    let router = useRouter()
    const store = useStore()
    const route = useRoute()
    let payShow = ref(false);
    let concelShow = ref(false);
    let checked = ref(1);
    const active = ref(0);
    const showDia = ref(false);
    const checkedDia = ref('001');
    const messageDia = ref('');

    let orderdetail = ref({});

    const getDetail = () => {
        let formData = new FormData();
        formData.append('data',JSON.stringify({
            function : 'selectSubscribeOrder',
            token : store.state.token,
            orderId : route.query.orderId,
            userid : store.state.uid,
            login_userId_base : store.state.uid,
            _validate : '1',
            standardFlag: "0",
            source: "h5",
            version : store.state.version,
            newversion : store.state.newversion
        }))

        formData.append('encryption',false)
        orderApi.orderAction(formData).then((res)=>{
            orderdetail.value = res.data;
        })
    }
    let serviceList = ref([]);

    const getServiceList = () => {
        let formData = new FormData();
        formData.append('data',JSON.stringify({
            function : 'selectSubOrderServiceList',
            token : store.state.token,
            orderId : route.query.orderId,
            userid : store.state.uid,
            login_userId_base : store.state.uid,
            _validate : '1',
            standardFlag: "0",
            _from: "h5",
            version : store.state.version,
            newversion : store.state.newversion
        }))

        formData.append('encryption',false)
        orderApi.orderAction(formData).then((res)=>{
            serviceList.value = res.list;
        })
    }

    getDetail()
    getServiceList()

    const conext = () => {
        window.location.href = 'tel://' + orderdetail.value.serverUserMobile;
    }

    const nerseShow = computed(()=>{
        if(['7','6','3','-1'].indexOf(orderdetail.value.orderStatus) >= 0){
            return false;
        }
        if(orderdetail.value.orderStatus == 0 && (orderdetail.value.payStatus == 0 || orderdetail.value.payStatus == -1) ){
            return false;
        }
        return true;
    })


     const changeText = (statu,payStatus) => {
        let sta = ''
        if (statu == -1) {
            sta = '已过期'
        }
        if (statu == 7) {
            sta = '人工取消'
        }
        if (statu == 0 && payStatus == 0) {
            sta = '待抢约'
        }
        if (statu == 0 && payStatus == -1) {
            sta = '待付款'
        }

        if (statu == 1) {
            sta = '待服务';
        }

        if (statu == 2) {
            sta = '已服务'
        }
        if (statu == 3) {
            sta = '已取消'

        }
        if (statu == 4) {
            sta = '已报价'
        }
        if (statu == 6) {
            sta = '已关闭'
        }
        if (statu == 7) {
            sta = '人工取消'

        }
        if (statu == 8) {
            sta = '送药员确认已送货'

        }
        if (statu == 9) {
            sta = '用户拒收'
        }
        return sta;
    }

    const toPay = () => {
        router.push({
            name : 'pay',
            query : {
                userId : store.state.uid,
                fee : orderdetail.value.aPrice,
                orderId : orderdetail.value.orderId,
                roleCode : orderdetail.value.roleType,
                serviceCode : orderdetail.value.serviceCode
            }
        })
    }

    const concel = () => {
      // window.location.href = 'tel://' + 4008006996;
      showDia.value = true;

    }
    //确认已服务
    const confirmService = () => {
      Dialog.alert({
        title:'',
        message:'确认已完成服务',
        confirmButtonText:'确定',
        showCancelButton:true,
        confirmButtonColor:'rgb(0, 194, 145)',
        // cancelButtonColor:'rgb(0, 194, 145)'
      }).then(()=> {

        console.log('完成服务---')

        let formData = new FormData();
        formData.append('data',JSON.stringify({
          function : 'door',
          token : store.state.token,
          user_id : store.state.uid,
          order_id : route.query.orderId,
          login_userId_base : store.state.uid,
          source: "h5",
          sid:"80009000075",
          version : store.state.version,
          newversion : store.state.newversion

        }))

        formData.append('encryption',false);

        orderApi.orderAction(formData).then((res)=>{
          if(res.code == "0000"){
            Toast("已确认");
            setTimeout( function () {
              router.push({
                path : '/orderList'
              })
            },800)
          }

        })

      })
    }
    const concelOnSubmit = () => {
      let formData = new FormData();
      formData.append('data',JSON.stringify({
        function : 'cancel',
        cancelreasonId : checkedDia.value,
        cancelReasonContent: messageDia.value,
        token : store.state.token,
        user_id : store.state.uid,
        order_id : route.query.orderId,
        login_userId_base : store.state.uid,
        type : '0',
        source: "h5",
        version : store.state.version,
        newversion : store.state.newversion

      }))

      formData.append('encryption',false);

      orderApi.orderAction(formData).then((res)=>{
        if(res.code == "0000"){
            Toast("退款成功");
            setTimeout( function () {
              router.push({
                path : '/orderList'
              })
            },800)
        }

      })
    }
    const changeRadValue = (name) => {
         messageDia.value = ''
    }
    return {
        concel,
        toPay,
        changeText,
        nerseShow,
        conext,
        showDia,
        orderdetail,
        router,
        checked,
        active,
        payShow,
        concelShow,
        checkedDia,
        messageDia,
        concelOnSubmit,
        changeRadValue,
        confirmService
    }
  },
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>

     ::v-deep(.van-dialog){
       box-sizing: border-box;
        padding:0 25px;
       .van-radio{
          margin-bottom:8px;
       }
       .van-dialog__header{
         margin-bottom: 10px;
       }
       .van-radio-group{
         font-size:27px;
       }
       .txt{
         font-size: 27px;
         margin:20px 0;
       }
     }
     ::v-deep(.van-cell){
        padding:0;
       margin:10px 0;
     }
     ::v-deep(textarea){
       padding:15px;
       background: #F7F7F7;
       border-radius: 10px;
     }
     ::v-deep(.van-uploader__upload){
       border:1px dashed #999;
     }

    .colorblur{
        color:#1083FF!important;
    }
    .active_btn{
        color:#00C291!important;
        border-color:#00C291!important;
    }
    .concen_tips{
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #333333;
        padding:20px 50px;
    }
    .btn_content{
        display: flex;
        justify-content: space-around;
        margin-bottom:30px;
        ::v-deep(button){
            width: 300px;
            height: 72px;
        }
    }
    .radio_content{
        padding:15px;
    }
    .concel_title{
        font-size: 30px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        text-align: center;
        line-height: 90px;
        height: 90px;
    }
    .reson{
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #030303;
        padding:50px 40px;
        padding-bottom: 20px;
    }
    .pay_input{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding:0 40px;
        font-size: 44px;
        font-family: Gotham-Bold, Gotham;
        font-weight: bold;
        color: #313131;
        padding-bottom: 30px;
        border-bottom: 1px solid #EDEDED;
    }
    .top{
        // padding:25px 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img{
            width: 100%;
        }

    }
    .information{
        background: #fff;
        padding:30px;
        padding-bottom:10px;
        .title{
            display: flex;
            justify-content: space-between;
            font-size: 28px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #313131;
            border-bottom: 1px solid #F2F2F2;
            padding-bottom: 20px;
        }
        .bottom_nurse{
            padding:26px 0;
            border-top:1px solid #F2F2F2;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .des{
                font-size: 28px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 350px;
            }
            .divider{
                height: 30px;
                width: 1px;
                background: #F2F2F2;
            }
            img{
                width: 35px;
                align-items: center;
                margin-right:12px;
            }
        }
        .content{
            padding:9px;
            .list{
                display: flex;
                font-size: 28px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                position: relative;
                margin:20px 0;
                .list_title{
                    width: 215px;
                    color: #919090;
                    flex-shrink: 0;
                }
                .arrow{
                    position: absolute;
                    right: 0;
                    top:8px;
                }
            }
        }
        .status{
            color:#00C291;
        }
    }
    .btn_btm_cont{
        width: 100%;
        position: fixed;
        bottom: 0;
        left:0;
        padding:20px 0;
        display: flex;
        justify-content: flex-end;
        background: #fff;
        z-index: 999;
        border-top:1px solid #f5f5f5;
        .btn_inner{
            padding:0 30px;
            display: flex;
            justify-content: flex-end;
        }
        .order_btn{
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            height: 50px;
            padding:0 22px;
            line-height: 50px;
            border-radius: 25px;
            border: 1px solid #999999;
            margin-left:20px;
        }
    }
    .grey{
        color:#666666!important;
    }
     .grid {
       overflow: hidden;
     }
     .cell {
       margin:0 auto;
       //width: 300px;
       box-sizing: border-box;
       display: table;
     }

     .card {
       background: white;
       display: table-cell;
       text-align: center;
       vertical-align: middle;
     }
     .load_text{
       margin:0 auto;
       text-align: center;
       width: 400px;
       font-size: 20px;
     }
     .load_box{
       box-sizing: border-box;
       padding:30px 0 0 30px;
       //display: flex;
       color:#919090;
     }

     @-moz-keyframes timer-loader {
       0% {
         -moz-transform: rotate(0deg);
         transform: rotate(0deg);
       }
       100% {
         -moz-transform: rotate(360deg);
         transform: rotate(360deg);
       }
     }
     @-webkit-keyframes timer-loader {
       0% {
         -webkit-transform: rotate(0deg);
         transform: rotate(0deg);
       }
       100% {
         -webkit-transform: rotate(360deg);
         transform: rotate(360deg);
       }
     }
     @keyframes timer-loader {
       0% {
         -moz-transform: rotate(0deg);
         -ms-transform: rotate(0deg);
         -webkit-transform: rotate(0deg);
         transform: rotate(0deg);
       }
       100% {
         -moz-transform: rotate(360deg);
         -ms-transform: rotate(360deg);
         -webkit-transform: rotate(360deg);
         transform: rotate(360deg);
       }
     }
     /* :not(:required) hides this rule from IE9 and below */
     .timer-loader:not(:required) {
       border: 6px solid #00C291;
       -moz-border-radius: 40px;
       -webkit-border-radius: 40px;
       border-radius: 40px;
       -moz-box-sizing: border-box;
       -webkit-box-sizing: border-box;
       box-sizing: border-box;
       display: inline-block;
       overflow: hidden;
       position: relative;
       text-indent: -9999px;
       width: 80px;
       height: 80px;
     }
     .timer-loader:not(:required)::before {
       -moz-animation: timer-loader 1250ms infinite linear;
       -webkit-animation: timer-loader 1250ms infinite linear;
       animation: timer-loader 1250ms infinite linear;
       -moz-transform-origin: 3px 3px;
       -ms-transform-origin: 3px 3px;
       -webkit-transform-origin: 3px 3px;
       transform-origin: 3px 3px;
       background: #00C291;
       -moz-border-radius: 3px;
       -webkit-border-radius: 3px;
       border-radius: 3px;
       content: '';
       display: block;
       position: absolute;
       width: 6px;
       height: 29.2px;
       left: 32px;
       top: 32px;
     }
     .timer-loader:not(:required)::after {
       -moz-animation: timer-loader 15000ms infinite linear;
       -webkit-animation: timer-loader 15000ms infinite linear;
       animation: timer-loader 15000ms infinite linear;
       -moz-transform-origin: 3px 3px;
       -ms-transform-origin: 3px 3px;
       -webkit-transform-origin: 3px 3px;
       transform-origin: 3px 3px;
       background: #00C291;
       -moz-border-radius: 3px;
       -webkit-border-radius: 3px;
       border-radius: 3px;
       content: '';
       display: block;
       position: absolute;
       width: 6px;
       height: 26px;
       left: 32px;
       top: 32px;
     }

     @-moz-keyframes three-quarters-loader {
       0% {
         -moz-transform: rotate(0deg);
         transform: rotate(0deg);
       }
       100% {
         -moz-transform: rotate(360deg);
         transform: rotate(360deg);
       }
     }
     @-webkit-keyframes three-quarters-loader {
       0% {
         -webkit-transform: rotate(0deg);
         transform: rotate(0deg);
       }
       100% {
         -webkit-transform: rotate(360deg);
         transform: rotate(360deg);
       }
     }
     @keyframes three-quarters-loader {
       0% {
         -moz-transform: rotate(0deg);
         -ms-transform: rotate(0deg);
         -webkit-transform: rotate(0deg);
         transform: rotate(0deg);
       }
       100% {
         -moz-transform: rotate(360deg);
         -ms-transform: rotate(360deg);
         -webkit-transform: rotate(360deg);
         transform: rotate(360deg);
       }
     }


</style>
